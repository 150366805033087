<template>
  <div>{{ tTrim("playtime") }}: {{ time }}</div>
</template>

<script>
import moment from "moment-timezone";

export default {
  setup() {
    const { tTrim } = useHLGLocalisation();
    return { tTrim };
  },
  computed: {
    time() {
      const duration = moment.duration(moment(this.$store.state.time).diff(this.$store.state.app.loadedAt));
      const seconds = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
      const minutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
      const hours = duration.hours() < 10 ? `0${duration.hours()}` : duration.hours();

      return `${hours > 0 ? `${hours}:` : ""}${minutes}:${seconds}`;
    },
  },
};
</script>
