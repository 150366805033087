<template>
  <div class="modal__background" @click="$emit('close')">
    <div class="modal__container" @click.stop>
      <div class="modal__header">
        <slot name="header"> </slot>
        <button class="button-circle button-circle--modal" @click="$emit('close')">×</button>
      </div>

      <div class="modal__body">
        <slot name="body"> </slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal__background {
  position: fixed;
  z-index: 9998;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.4);
}

.modal__container {
  min-width: 40vw;

  @apply flex flex-col shadow-md rounded;

  color: VarsToHSL(--ttbw-modal-text-colour);
  background: VarsToHSL(--ttbw-modal-background-colour);
}

.modal__header {
  @apply flex flex-row justify-between w-full font-bold;
  padding: $spacing;

  background-color: hsl(
    var(--ttbw-modal-background-colour-h),
    var(--ttbw-modal-background-colour-s),
    calc(var(--ttbw-modal-background-colour-l) - 10%)
  );
}

.modal__body {
  padding: $spacing * 2 $spacing;
}

.button-circle--modal {
  background: VarsToHSL(--ttbw-modal-background-colour);
  &:hover {
    background: hsl(
      var(--ttbw-modal-background-colour-h),
      var(--ttbw-modal-background-colour-s),
      calc(var(--ttbw-modal-background-colour-l) + #{$lightness-3})
    );
  }
}
</style>

<script>
export default {};
</script>
